<template>
  <div>
    <v-card class="mb-6">
      <v-card-title>FAQ</v-card-title>
      <v-card-text style="text-align: center;">-ESTA SECCIÓN ESTA EN DESARROLLO-</v-card-text>
      <!-- <v-card-text>All the best for your new project.</v-card-text>
      <v-card-text>
        Please make sure to read our <a
          href="https://themeselection.com/demo/materio-vuetify-vuejs-admin-template/documentation"
          target="_blank"
          class="text-decoration-none"
        >
          Template Documentation
        </a> to understand where to go from here and how to use our template.
      </v-card-text> -->
    </v-card>

    <!-- <v-card>
      <v-card-title>Want to integrate JWT? 🔒</v-card-title>
      <v-card-text>We carefully crafted JWT flow so you can implement JWT with ease and with minimum efforts.</v-card-text>
      <v-card-text>Please read our  JWT Documentation to get more out of JWT authentication.</v-card-text>
    </v-card> -->
  </div>
</template>

<script>


export default {
  data(){
     
    return {

    };

  }
  

}

</script>